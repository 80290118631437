
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import SearchForm from '~/components/search-form/SearchForm.vue'
import Features from '~/components/index/Features.vue'

@Component({
  components: {
    Features,
    SearchForm
  }
})
export default class extends Vue {
  @Prop({
    type: Boolean,
    default: true
  }) changeRoute!: boolean
}
