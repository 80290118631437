
import { Component, Vue } from 'nuxt-property-decorator'
import { TheMask } from 'vue-the-mask'
import DateInput from '~/components/inputs/DateInput.vue'
import DriverSearchForm from '~/components/search-form/DriverSearchForm.vue'
import VinSearchForm from '~/components/search-form/VinSearchForm.vue'

@Component({
  components: {
    VinSearchForm,
    DriverSearchForm,
    DateInput,
    TheMask
  }
})
export default class extends Vue {
}
