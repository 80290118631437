
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { TheMask } from 'vue-the-mask'
import { required, minLength } from 'vuelidate/lib/validators'
import { ErrorValidation } from '~/types/ErrorValidation'
import DateInput from '@/components/inputs/DateInput.vue'

interface IForm {
  vuNumber: string,
  date: string
}

@Component({
  components: {
    DateInput,
    TheMask
  }
})
export default class extends Vue {
  @Prop() dataErrorFormValidations!: ErrorValidation

  isClientErrorValid: boolean = false
  form: IForm = {
    vuNumber: '',
    date: ''
  }

  vuMaskTokens = {
    '#': { pattern: /\d/ },
    F: {
      pattern: /[0-9a-zA-Zа-яА-я]/,
      transform: (v: string) => v.toLocaleUpperCase()
    }
  }

  errorClientValid () {
    this.isClientErrorValid = false
    this.$v.$touch()

    if (this.$v.form.$error) {
      this.isClientErrorValid = true
    } else {
      this.$router.push({
        path: '/driver-search/',
        query: {
          document_value: this.form.vuNumber,
          date: this.form.date
        }
      })
      this.form.vuNumber = ''
      this.form.date = ''
      this.$v.$reset()
    }
  }

  validations () {
    return {
      form: {
        date: {
          required,
          minLength: minLength(8),
          isTrueDate: (date: string) => {
            const d = new Date(date)
            return +date.substr(8) === d.getDate()
          }
        },
        vuNumber: {
          required,
          minLength: minLength(10),
          validData (val: string) {
            return /^[0-9]{2}(?:[0-9]{2}|[а-яА-Яa-zA-Z]{2})[0-9]{6}$/.test(val)
          }
        }
      }
    }
  }
}
