<template>
  <div class="info-block">
    <div class="info-block__header info-block__section">
      <img
        v-lazy-load
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        :data-src="require('~/assets/img/info-block/payment.svg')"
        class="info-block__img"
        width="44"
        height="44"
        alt="Оплата штрафов ГИБДД"
      >
      <h2 class="info-block__title">
        Оплата штрафов ГИБДД
      </h2>
      <button class="info-block__btn" @click="changeView">
        <span v-if="!isShow">▼</span>
        <span v-else>▲</span>
      </button>
    </div>
    <div v-if="isShow" class="info-block__text">
      <p class="info-block__p info-block__section">
        <strong>Оплата штрафов ГИБДД</strong> на нашем сервисе происходит через следующие банки:
      </p>
      <ul class="info-block__list info-block__section">
        <li>ПНКО «Электронные платежи»</li>
        <li>ПАО «Промсвязьбанк»</li>
        <li>НКО «МОНЕТА.РУ» (ООО)</li>
        <li>ПАО «МТС Банк»</li>
        <li>АО Банк «ККБ»</li>
        <li>ООО НКО «ПэйЮ»</li>
      </ul>
      <p class="info-block__p info-block__section">
        Преимущество нашего сервиса в том, что мы сделали <strong>оплату штрафов ГИБДД</strong> максимально комфортной и быстрой через наш сайт или мобильное приложение. Выбор перечисленных банков связан с удобством внесения платежей, так как нами принимаются к оплате любые банковские карты. Оплата обрабатывается в течение нескольких минут после перечисления денежных средств, далее в короткий срок информация о штрафе исчезает из базы ГИБДД и ГИС ГМП. Подтверждением оплаты является квитанция, которая будет выслана на электронную почту пользователя и сохранится в системе.
      </p>
      <h3 class="info-block__h3 info-block__section">
        Гарантированная оплата со скидкой 50%
      </h3>
      <p class="info-block__p info-block__section">
        В течение 20 дней с даты вынесения постановления водитель может оплатить штраф со скидкой 50%. На нашем сайте вы не упустите этот срок: если вы подписались на уведомления, мы предупредим вас об окончании срока льготной оплаты. Также после проверки вы увидите, сколько дней осталось до окончания этого срока.
      </p>
      <p class="info-block__p info-block__section">
        Срок погашения штрафа зависит от скорости работы ГИБДД, но со своей стороны мы сделаем все возможное, чтобы задолженность была погашена как можно скорее. В случае, если платеж по какой-то причине не проходит, мы связываемся с банком и ГИБДД и добиваемся корректного погашения, в противном случае вернем полную стоимость оплаты.
      </p>
      <h3 class="info-block__h3 info-block__section">
        Срок оплаты
      </h3>
      <p class="info-block__p info-block__section">
        Согласно статье 32.2 КоАП РФ, в период 60 дней с даты вынесения постановления водитель обязан заплатить начисленное административное взыскание. На обжалование вынесенного постановления дается 10 дней. Чтобы не просрочить оплату и не накапливать долг, оплачивайте через наш сайт, либо мобильное приложение, которые разработаны для вашего удобства, и моментальной оплаты задолженности.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentInfo',
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    changeView () {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style scoped>
.info-block__text {
  margin-top: 10px;
}

.info-block__btn {
  background: none;
  border: none;
  margin-left: auto;
  font-size: 20px;
  color: #96D1FF;
}
</style>
