<template>
  <div class="info-block" :style="!isShow ? 'height: 86px' : ''">
    <div class="info-block__header info-block__section">
      <img
        v-lazy-load
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        :data-src="require('~/assets/img/info-block/guarantee.svg')"
        class="info-block__img"
        width="44"
        height="44"
        alt="Гарантия оплаты и безопасность платежей"
      >
      <h2 class="info-block__title">
        Гарантия оплаты и безопасность платежей
      </h2>
      <button class="info-block__btn" @click="changeView">
        <span v-if="!isShow">▼</span>
        <span v-else>▲</span>
      </button>
    </div>
    <div v-if="isShow" class="info-block__text">
      <p class="info-block__p info-block__section">
        Мы предоставляем гарантию безопасности оплаты. Если по каким-либо причинам оплаченный штраф не исчезает из базы, мы связываемся с сотрудниками ГИБДД для урегулирования вопроса либо возвращаем деньги пользователю.
      </p>
      <p class="info-block__p info-block__section">
        Платежи, произведенные на сервисе, безопасны, так как все передаваемые данные шифруются по протоколу TLS, безопасность платежной формы подтверждена международным сертификатом PCI DSS.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuaranteeInfo',
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    changeView () {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style scoped lang="scss">
.info-block {
  width: 100%;
}
.info-block__text {
  margin-top: 10px;
}

.info-block__btn {
  background: none;
  border: none;
  margin-left: auto;
  font-size: 20px;
  color: #96D1FF;
}
</style>
