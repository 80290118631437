<template>
  <div class="source">
    <h2 class="source__title">
      Официальные источники данных
    </h2>
    <div class="source__list">
      <div class="source__list__item source-item">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/gibdd.png')"
          width="40.17"
          height="36.55"
          alt="Сайт ГИБДД"
          class="source-item__logo"
        >
        <div class="source-item__title">
          Сайт ГИБДД
        </div>
      </div>
      <div class="source__list__item source-item">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/fns.png')"
          width="40.17"
          height="44.58"
          alt="Федеральное Казначейство России (ГИС ГМП)"
          class="source-item__logo"
        >
        <div class="source-item__title">
          Федеральное Казначейство России<br>(ГИС ГМП)
        </div>
      </div>
      <div class="source__list__item source-item">
        <img
          v-lazy-load
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          :data-src="require('~/assets/img/source/fssp.png')"
          width="40.17"
          height="40.56"
          alt="База ФССП"
          class="source-item__logo"
        >
        <div class="source-item__title">
          База ФССП
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfficialSource'
}
</script>

<style lang="scss" scoped>
.source {
  &__title {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 16px;
  }

  &__list {
    display: flex;

    &__item {
      & + & {
        margin-left: 60px;
      }
    }
  }

  @include bp-max($mq-mobile-max) {
    &__list {
      flex-direction: column;

      &__item {
        & + & {
          margin-top: 16px;
          margin-left: 0;
        }
      }
    }
  }
}

.source-item {
  display: flex;
  align-items: center;

  &__logo {
    margin-right: 10px;
    flex: 0 0 auto;
  }

  &__title {
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
