<template>
  <div class="info-block">
    <div class="info-block__header info-block__section">
      <img
        v-lazy-load
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        :data-src="require('~/assets/img/info-block/' + iconName + '.svg')"
        class="info-block__img"
        width="44"
        height="44"
        :alt="title"
      >
      <h2 class="info-block__title">
        {{ title }}
      </h2>
      <button class="info-block__btn" @click="changeView">
        <span v-if="!isShow">▼</span>
        <span v-else>▲</span>
      </button>
    </div>
    <div v-if="isShow" class="info-block__text">
      <slot name="text" />
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'FaqItemSlot'
})
export default class extends Vue {
  @Prop({}) iconName
  @Prop({}) title

  isShow = false

  changeView () {
    this.isShow = !this.isShow
  }
}
</script>

<style scoped>
.info-block__text {
  margin-top: 10px;
}

.info-block__btn {
  background: none;
  border: none;
  margin-left: auto;
  font-size: 20px;
  color: #96D1FF;
}
</style>
