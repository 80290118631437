
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { TheMask } from 'vue-the-mask'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { ErrorValidation } from '~/types/ErrorValidation'
import DateInput from '~/components/inputs/DateInput.vue'
import DriverSearchForm from '~/components/search-form/DriverSearchForm.vue'

interface IForm {
  vinNumber: string
}

@Component({
  components: {
    DriverSearchForm,
    DateInput,
    TheMask
  }
})
export default class extends Vue {
  @Prop() dataErrorFormValidations!: ErrorValidation

  isAutoErrorValid: boolean = false
  form: IForm = {
    vinNumber: ''
  }

  autoMaskTokens = {
    A: {
      pattern: /[0-9ABCDEFGHJKLMNPRSTUVWXYZabcdefghjklmnprstuvwxyz]/,
      transform: (v: string) => v.toLocaleUpperCase()
    }
  }

  errorAutoValid () {
    this.isAutoErrorValid = false
    this.$v.$touch()
    if (!this.dataErrorFormValidations) {
      // @ts-ignore
      if (this.$v.form.$error) {
        this.isAutoErrorValid = true
      } else {
        this.$router.push({
          path: '/vin-search/',
          query: {
            document_value: this.form.vinNumber
          }
        })
        this.form.vinNumber = ''
      }
    }
  }

  validations () {
    return {
      form: {
        vinNumber: {
          required,
          minLength: minLength(17),
          maxLength: maxLength(17)
          // Рандомный формат заполнения
          // validData: (val: string) => {
          //   const allowChars = 'ABCDEFGHJKLMNPRSTUVWXYabcdefghjklmnprstuvwxyz'
          //   return !val || new RegExp(`([${allowChars}]{2}\\d[${allowChars}]{4}\\d[${allowChars}]{2}\\d{6})`).test(val)
          // }
        }
      }
    }
  }
}
