<template>
  <div class="info-block">
    <div class="info-block__header info-block__section">
      <img
        v-lazy-load
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        :data-src="require('~/assets/img/info-block/where.svg')"
        class="info-block__img"
        width="44"
        height="44"
        alt="Где можно проверить штрафы ГИБДД"
      >
      <h2 class="info-block__title">
        Где можно проверить штрафы ГИБДД
      </h2>
      <button class="info-block__btn" @click="changeView">
        <span v-if="!isShow">▼</span>
        <span v-else>▲</span>
      </button>
    </div>
    <div v-if="isShow" class="info-block__text">
      <p class="info-block__p info-block__section">
        <strong>Проверка штрафов ГИБДД по номеру автомобиля</strong> возможна с помощью нашего бесплатного сервиса, позволяющего быстро и без регистрации получить достоверную полную информацию. Достаточно ввести в специальную форму заполнения на сайте госномер авто, номер СТС, УИН или номер водительского удостоверения. Если в материалах есть фото- или видеофиксация, то дополнительно указывается государственный номер автомобиля.
      </p>
      <p class="info-block__p info-block__section">
        <strong>Поиск штрафов</strong> можно осуществить с помощью сайта <strong>ГИБДД</strong>, а также с помощью разнообразных интернет-порталов по проверке и оплате задолженностей. Сведения, предоставляемые такими интернет-ресурсами, могут содержать неполную информацию по количеству штрафов. То есть необходимо смотреть все эти сайты и затем объединять сведения. Наш сервис собирает все имеющиеся на дату поиска штрафы за нарушение ПДД и предоставляет их по запросу пользователя.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhereInfo',
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    changeView () {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style scoped>
.info-block__text {
  margin-top: 10px;
}

.info-block__btn {
  background: none;
  border: none;
  margin-left: auto;
  font-size: 20px;
  color: #96D1FF;
}
</style>
