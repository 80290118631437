<template>
  <div class="info-block">
    <div class="info-block__header info-block__section">
      <img
        v-lazy-load
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        :data-src="require('~/assets/img/info-block/important.svg')"
        class="info-block__img"
        width="44"
        height="44"
        alt="Почему важно вовремя платить штрафы ГИБДД"
      >
      <h2 class="info-block__title">
        Почему важно вовремя платить штрафы ГИБДД
      </h2>
      <button class="info-block__btn" @click="changeView">
        <span v-if="!isShow">▼</span>
        <span v-else>▲</span>
      </button>
    </div>
    <div v-if="isShow" class="info-block__text">
      <p class="info-block__p info-block__section">
        Любой водитель сталкивался с нарушением Правил дорожного движения (далее ‒ ПДД) и наложением штрафа. Иногда это происходит после остановки на посту инспектором ГИБДД либо после фиксации нарушения средствами фото- и видеокамер на проезжей части. Однако не всегда штрафы, зафиксированные такими средствам, в установленный срок в виде протокола поступают в адрес нарушителя, у которого имеется 60 дней для погашения в добровольном порядке задолженности перед государством. Если это постановление не будет обжаловано в указанный в законе срок или оплата не будет произведена, то штраф приобретает статус просроченного. Бывает и так, что водитель не знает о наличии просрочки, поэтому возникает необходимость проверять эту информацию во избежание привлечения к ответственности.
      </p>
      <p class="info-block__p info-block__section">
        В случае просрочки судебные приставы возбуждают исполнительное производство и направляют в кредитные организации запрос для списания с действующих банковских счетов суммы задолженности. Также работодателю нарушителя может быть направлено заявление об удержании из заработной платы суммы для оплаты штрафов ГИБДД. Перечень дополнительных последствий неоплаты следующий:
      </p>
      <ul class="info-block__list info-block__section mt-0">
        <li>запрет на выезд за пределы РФ;</li>
        <li>увеличение суммы долга в два раза в результате начисления пени за просрочку обязательства;</li>
        <li>привлечение к обязательным работам;</li>
        <li>привлечение к уголовной ответственности;</li>
        <li>лишение водительских прав.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportantInfo',
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    changeView () {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style scoped>
.info-block__text {
  margin-top: 10px;
}

.info-block__btn {
  background: none;
  border: none;
  margin-left: auto;
  font-size: 20px;
  color: #96D1FF;
}
</style>
