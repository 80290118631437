<template>
  <div class="info-block">
    <div class="info-block__header info-block__section">
      <img
        v-lazy-load
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        :data-src="require('~/assets/img/info-block/features.svg')"
        class="info-block__img"
        width="44"
        height="44"
        alt="Почему выбирают нас"
      >
      <h2 class="info-block__title">
        Почему выбирают нас
      </h2>
      <button class="info-block__btn" @click="changeView">
        <span v-if="!isShow">▼</span>
        <span v-else>▲</span>
      </button>
    </div>
    <div v-if="isShow" class="info-block__text">
      <ul class="info-block__list info-block__section">
        <li>самые актуальные данные из официальных источников;</li>
        <li>поддержка пользователей 24/7;</li>
        <li>мгновенные уведомления о появлении новых штрафов (при подключении уведомлений либо автоматически после оплаты) ;</li>
        <li>поиск бесплатный и не требует регистрации;</li>
        <li>предоставление фото нарушения;</li>
        <li>благодаря сервису вы не пропустите срок оплаты со скидкой 50 %, т.к. в каждой карточке штрафа указаны срок действия скидки и и общий срок оплаты до передачи дела ФССП;</li>
        <li>сохранение данных, которые ранее указывались водителем, для повторной проверки.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesInfo',
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    changeView () {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style scoped>
.info-block__text {
  margin-top: 10px;
}

.info-block__btn {
  background: none;
  border: none;
  margin-left: auto;
  font-size: 20px;
  color: #96D1FF;
}
</style>
