
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask
  }
})
export default class extends Vue {
  @Prop({
    type: String,
    default: ''
  }) value!: string
}
